.quantity-selector {
  display: flex;
  align-items: center;
}

.quantity-selector button {
  background-color: rgb(255 129 63);
  color: white;
  border: none;
  padding: 5px 5px 5px 5px;
  cursor: pointer;
  font-size: 16px;
}

.quantity-selector button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.quantity-selector input {
  width: 50px;
  text-align: center;
  border: 1px solid #cccccc;
  padding: 5px;
  font-size: 16px;
  margin: 0 5px;
}

.quantity-selector button:hover:not(:disabled) {
  background-color: rgb(255 129 63);
}
